<template>
  <div v-if="isAuthenticated">
    <h1>{{ $t('views.profile.h1_title') }}</h1>
    <form novalidate @submit.prevent="submit()">
      <div class="text-center">
        <v-row class="my-0">
          <v-col class="text-left">
            <p>{{ $t('views.profile.p_text') }}</p>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col md="8" lg="6" class="mx-auto">
            <v-avatar v-if="userAvatar" size="150">
              <img class="avatar--image" :src="userAvatar" :alt="$t('views.profile.img_profile')" />
            </v-avatar>
            <fa-icon
              v-else
              :class="$theme.app.navigation.buttons.open.text"
              class="mb-2"
              size="9x"
              :icon="['fal', 'user-circle']"
            ></fa-icon>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col md="8" lg="6" class="mx-auto">
            <v-text-field
              v-model="userName"
              :disabled="disableFormElements"
              :error-messages="userNameErrors"
              :label="$t('views.profile.text_field_name')"
              hide-details="auto"
              :counter="20"
              @input="userNameInput()"
              @blur="userNameInput()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col md="8" lg="6" class="mx-auto">
            <v-text-field
              v-model="userInfo"
              :disabled="disableFormElements"
              :error-messages="userInfoErrors"
              :label="$t('views.profile.text_field_info')"
              hide-details="auto"
              :counter="40"
              @input="userInfoInput()"
              @blur="userInfoInput()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col md="8" lg="6" class="mx-auto">
            <div style="margin-left: -33px">
              <v-file-input
                v-model="avatar"
                :disabled="disableFormElements"
                :error-messages="userAvatarErrors"
                show-size
                :label="$t('views.profile.file_input_label')"
                @click="userAvatarClick"
              ></v-file-input>
            </div>
          </v-col>
        </v-row>
        <div class="text-center my-3">
          <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
        </div>
        <v-row class="my-0">
          <v-col md="8" lg="6" class="mx-auto">
            <p v-if="profileUpdated">{{ $t('views.profile.p_profile_saved') }}</p>
            <v-btn v-else color="$theme.btn.color" outlined rounded type="submit" class="mr-2">
              <fa-icon class="mr-2" size="lg" :icon="['fal', 'save']" />
              {{ $t('views.profile.btn_save') }}
            </v-btn>
            <btn-home v-if="profileUpdated"></btn-home>
            <v-btn
              v-else
              :disabled="disableFormElements || profile.userName === ''"
              color="red"
              :href="$t('hyperlinks.home.href')"
              outlined
              rounded
            >
              <fa-icon class="mr-2" size="lg" :icon="['fal', 'times']" />{{ $t('views.profile.btn_cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </form>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { theme } from '@/mixins'
import { validationMixin } from 'vuelidate'
import BtnHome from '@/components/btn/Home'

const maxCharLength = (param) => (value) => {
  if (value === '') return false
  return !(value.match(/./gu).length > param)
}
const imageFileTypes = (base64Data) => {
  if (base64Data === null || base64Data === '') return true
  const imageFileType = base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
  return ['jpeg', 'png', 'webp'].includes(imageFileType)
}

export default {
  name: 'Profile',
  components: { Adslot, BtnHome },
  mixins: [validationMixin, theme],
  data() {
    return {
      avatar: [],
      userAvatar: null,
      userName: '',
      userInfo: '',
      profileUpdated: false,
      disableFormElements: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'profile']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    userNameErrors() {
      const errors = []
      if (!this.$v.userName.$dirty) return errors
      !this.$v.userName.required && errors.push(this.$t('vuelidate.error.required'))
      !this.$v.userName.maxCharLength && errors.push(this.$t('vuelidate.error.max_length'))
      return errors
    },
    userInfoErrors() {
      const errors = []
      if (!this.$v.userInfo.$dirty) return errors
      !this.$v.userInfo.required && errors.push(this.$t('vuelidate.error.required'))
      !this.$v.userInfo.maxCharLength && errors.push(this.$t('vuelidate.error.max_length'))
      return errors
    },
    userAvatarErrors() {
      const errors = []
      if (!this.$v.userAvatar.$dirty) return errors
      !this.$v.userAvatar.imageFileTypes && errors.push(this.$t('vuelidate.error.max_length'))
      return errors
    },
  },
  validations: {
    userName: { required, maxCharLength: maxCharLength(20) },
    userInfo: { required, maxCharLength: maxCharLength(40) },
    userAvatar: { imageFileTypes },
  },
  watch: {
    profile: function () {
      this.userName = this.profile.userName
      this.userInfo = this.profile.userInfo
      this.userAvatar = this.profile.userAvatar
    },
    avatar: async function () {
      if (typeof this.avatar !== 'undefined' && ['image/jpeg', 'image/png', 'image/webp'].includes(this.avatar.type)) {
        const image = await this.resize(this.avatar, 300)
        this.userAvatar = await this.blobToBase64(image)
      } else {
        this.userAvatar = null
      }
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ path: String(this.$t('hyperlinks.home.href')) })
    } else {
      this.userName = this.profile.userName
      this.userInfo = this.profile.userInfo
      this.userAvatar = this.profile.userAvatar
    }
  },
  methods: {
    ...mapActions(['setProfile']),
    async submit() {
      this.disableFormElements = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const com = new Com()
        const response = await com.updateProfile(this.userName, this.userInfo, this.userAvatar)
        if (typeof response === 'object') {
          this.setProfile({ userName: this.userName, userInfo: this.userInfo, userAvatar: this.userAvatar })
          this.profileUpdated = true
        }
      }
      this.disableFormElements = false
    },
    userNameInput() {
      this.userNameError = false
      this.$v.userName.$reset()
      this.profileUpdated = false
    },
    userInfoInput() {
      this.userInfoError = false
      this.$v.userInfo.$reset()
      this.profileUpdated = false
    },
    userAvatarClick() {
      this.$v.userInfo.$reset()
      this.profileUpdated = false
    },
    async blobToBase64(blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async resize(file, maxSize) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.createElement('canvas')
      const dataURItoBlob = function (dataURI) {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1])
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
        const max = bytes.length
        const ia = new Uint8Array(max)
        for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
        return new Blob([ia], { type: mime })
      }
      const resize = function () {
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height
            height = maxSize
          }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL('image/jpeg')
        return dataURItoBlob(dataUrl)
      }
      return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
          no(new Error('Not an image'))
          return
        }
        reader.onload = function (readerEvent) {
          image.onload = function () {
            return ok(resize())
          }
          image.src = String(readerEvent.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/styles/variables.scss';

.profile {
  &__avatar {
    &--text {
      font-size: 4rem;
      font-family: $header-font-family;
    }
  }
  &__name {
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1rem;
    letter-spacing: -0.0083333333em;
    font-family: $header-font-family;
  }
  &__caption {
    line-height: 14px !important;
  }
}
</style>
